var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "h1",
        {
          staticStyle: { "margin-bottom": "0px" },
          attrs: { "data-cy": "title" },
        },
        [_vm._v("\n    Forgot Password\n  ")]
      ),
      _c("h3", { attrs: { "data-cy": "displayText" } }, [
        _vm._v("\n    " + _vm._s(_vm.displayText) + "\n  "),
      ]),
      _c(
        "b-form-group",
        { attrs: { label: "Email:" } },
        [
          _c("b-form-input", {
            attrs: {
              "data-cy": "email",
              placeholder: "Email address",
              errors: _vm.errorsForEmail,
            },
            on: { input: (val) => (_vm.email = val) },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: {
            "data-cy": "submit",
            disabled: _vm.invalidEmail,
            variant: "primary",
          },
          on: { click: _vm.resetPassword },
        },
        [_vm._v("\n    Send Password Link\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }