<template>
  <b-container>
    <h1 data-cy="title" style="margin-bottom:0px">
      Forgot Password
    </h1>
    <h3 data-cy="displayText">
      {{ displayText }}
    </h3>
    <b-form-group label="Email:">
      <b-form-input
        v-model="email"
        data-cy="email"
        placeholder="Email address"
        :errors="errorsForEmail"
        @input="val => (email = val)"
      />
    </b-form-group>
    <b-button data-cy="submit" :disabled="invalidEmail" variant="primary" @click="resetPassword">
      Send Password Link
    </b-button>
  </b-container>
</template>

<script>
import { validEmail } from '@/common'
import http from '../http'

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: this.email || '',
      isComplete: false,
      errors: this.errors || {},
      message: this.message,
      accountResetNumber: '',
    }
  },
  computed: {
    invalidEmail() {
      return !validEmail().test(this.email)
    },
    errorsForEmail() {
      return (this.errors && this.errors['email_address']) || ''
    },
    displayText() {
      const number = this.accountResetNumber

      return this.isComplete ? 'If you don\'t receive an email with instructions to reset your password in the next 15 minutes, please call us to reset your password.' + number : ''
    },
    websiteSubdomain() {
      const tlds = location.hostname.split('.')
      return tlds.length > 0 ? tlds[0] : ''
    },
  },
  async mounted() {
    await this.setAccountResetNumber()
  },
  methods: {
    async setAccountResetNumber() {
      if (this.websiteSubdomain && this.websiteSubdomain !== 'localhost') {
        const response = await http.get(`/client/websites/by_subdomain/${this.websiteSubdomain}`)

        if (response && response.data && response.data.result) {
          this.accountResetNumber = response.data.result.phone_numbers.find((a) => a.kind === 'billing').phone_number
          this.accountResetNumber = this.accountResetNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        }
      }
    },
    async resetPassword() {
      try {
        await this.$store.dispatch('session/forgotPassword', {
          email: this.email,
          websiteSubdomain: this.websiteSubdomain,
        })

        this.isComplete = true
      } catch (error) {
        this.errors = error.data
        console.log('this.errors:', this.error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}

button:disabled {
  background-color: darkgray;
  border-color: darkgray;
}

#errors {
  color: $error-color;
}

div {
  max-width: 420px;
}

@media only screen and (max-width: 550px) {
  #forgot-password {
    margin-left: 0px;
  }
}
</style>
